import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {SecondaryCodesService} from '@matchsource/store/secondary-codes';
import orderBy from 'lodash-es/orderBy';
import {SecondaryCodeModel} from '@matchsource/models/secondary-codes';

export interface SecondaryCodeListModel {
  name: string;
  value: string;
  guid: MsApp.Guid;
}

@Injectable()
export class SecondaryCodeListService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly secondaryCodes: SecondaryCodesService) {}

  byTcId(tcId: MsApp.Guid, allCodes = false): Observable<SecondaryCodeListModel[]> {
    return this.secondaryCodes.tcSecondaryCodes(tcId).pipe(
      map(codes => codes.filter(code => allCodes || code.status)),
      map(codes => codes.map(code => ({name: code.tcSecondaryCode, value: code.tcSecondaryCode, guid: code.id}))),
      map(codes => orderBy(codes, 'name', 'asc')),
      takeUntil(this.destroy$)
    );
  }

  byCode(tcId: MsApp.Guid, secondaryCode: string): Observable<SecondaryCodeModel> {
    return this.secondaryCodes.tcSecondaryCodes(tcId).pipe(
      map(codes => codes.find(code => code.tcSecondaryCode === secondaryCode)),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
